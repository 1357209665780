import React, {Component} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import styled from 'styled-components';

import Header from './Header';
import TopBar from "./menu/TopBar";
import { HOME_PATH, SEARCH_PATH } from '../routes/paths';
import HomePage from './home/HomePage';
import SearchPage from "./search/SearchPage";

const AppWrapper = styled.div`
    height: ${({isHomePage = false}) => isHomePage ? '' : '100vh'};
    display: flex;
    flex-direction: column;
`;

const Main = styled.div`
    display: flex;
`;

const ContentWrapper = styled.div`
    flex: 1;
    margin-top: 6px;
    padding: 0 20px 0 20px;
    transition: all 0.5s ease;
    overflow-y: visible;
    overflow-x: hidden;
`;

class AppContent extends Component {

    render() {
        return (
            <AppWrapper>
                <div>
                    <Header />
                    <TopBar />
                </div>
                <Main>
                    <ContentWrapper id="contentWrapper">
                        <Switch>
                            <Route exact path={HOME_PATH} component={HomePage}/>
                            <Route path={SEARCH_PATH} component={SearchPage}/>
                            {/*<Route component={NotFoundPage}/>*/}
                        </Switch>
                    </ContentWrapper>
                </Main>
            </AppWrapper>
        );
    }
}


export default withRouter(AppContent);